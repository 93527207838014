import React, { useMemo } from 'react'
import { format } from 'date-fns'
import { Box, Tag, Text, Grid, GridItem } from '@chakra-ui/react'
import { RouteComponentProps } from '@reach/router'

import Seo from '~components/shared/seo'
import Heading from '~components/shared/heading'
import ContentBox from '~components/shared/content-box'
import ContentBoxTitle from '~components/shared/content-box-title'
import { useTicketOrderQuery } from '~graphql/generated'

interface Props extends RouteComponentProps {
  id?: string
}

const OrderDetailsPage: React.FC<Props> = ({ id }) => {
  const { data } = useTicketOrderQuery({
    skip: !id,
    variables: { id: id || '' },
  })

  const order = useMemo(() => data?.ticketorder, [data])

  return (
    <>
      <Seo title={`Order #${id}`} />
      <Heading>Order #{id}</Heading>
      <Grid templateColumns={['repeat(3, 1fr)']} gap={[4, 6]}>
        <GridItem colSpan={2}>
          <ContentBox>
            <ContentBoxTitle>Tickets ({order?.tickets_qty})</ContentBoxTitle>
            {order?.ticketstubs?.map(stub => (
              <Box key={stub?.ticket_id}>
                <Tag>{stub?.ticket_id}</Tag>
              </Box>
            ))}
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox>
            <ContentBoxTitle>Details</ContentBoxTitle>
            <Text>Credits cost: {(order?.ticket_sku.credit_cost * order?.tickets_qty) ?? "--"}</Text>
            <Text>
              Order date:{' '}
              {format(new Date(order?.created_at || null), 'MMM do yy hh:mm')}
            </Text>
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox>
            <ContentBoxTitle>Delivery Status</ContentBoxTitle>
          </ContentBox>
        </GridItem>
        <GridItem>
          <ContentBox>
            <ContentBoxTitle>Delivery Method</ContentBoxTitle>
          </ContentBox>
        </GridItem>
      </Grid>
      <pre>{JSON.stringify(data, undefined, 2)}</pre>
    </>
  )
}

export default OrderDetailsPage
